var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "d2-page-cover",
        [
          _c("d2-icon-svg", {
            staticClass: "logo",
            attrs: { name: "d2-admin" }
          }),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "div",
                { staticClass: "btn-group" },
                [
                  _c(
                    "span",
                    {
                      staticClass: "btn-group__btn",
                      on: {
                        click: function($event) {
                          return _vm.$open("https://github.com/d2-projects")
                        }
                      }
                    },
                    [_vm._v("开源组织")]
                  ),
                  _vm._v(" | "),
                  _c(
                    "span",
                    {
                      staticClass: "btn-group__btn",
                      on: {
                        click: function($event) {
                          return _vm.$open("https://d2.pub/zh/doc/d2-admin")
                        }
                      }
                    },
                    [_vm._v("文档")]
                  ),
                  _vm._v(" | "),
                  _c(
                    "span",
                    {
                      staticClass: "btn-group__btn",
                      on: {
                        click: function($event) {
                          return _vm.$open(
                            "https://github.com/d2-projects/d2-admin-start-kit"
                          )
                        }
                      }
                    },
                    [_vm._v("简化版")]
                  ),
                  _vm._v(" | "),
                  _c(
                    "span",
                    {
                      staticClass: "btn-group__btn",
                      on: {
                        click: function($event) {
                          return _vm.$open(
                            "https://juejin.im/user/57a48b632e958a006691b946/posts"
                          )
                        }
                      }
                    },
                    [_vm._v("掘金")]
                  ),
                  _vm._v(" | "),
                  _c(
                    "el-popover",
                    { attrs: { width: 172, trigger: "hover" } },
                    [
                      _c("p", { staticClass: "d2-mt-0 d2-mb-10" }, [
                        _vm._v("今日前端")
                      ]),
                      _c("img", {
                        staticStyle: { width: "172px" },
                        attrs: { src: require("./image/qr@2x.png") }
                      }),
                      _c(
                        "span",
                        {
                          staticClass: "btn-group__btn btn-group__btn--link",
                          attrs: { slot: "reference" },
                          slot: "reference"
                        },
                        [
                          _c("d2-icon", { attrs: { name: "weixin" } }),
                          _vm._v(" 微信公众号 ")
                        ],
                        1
                      ),
                      _c(
                        "p",
                        {
                          staticStyle: {
                            "font-size": "12px",
                            "margin-top": "0px",
                            "margin-bottom": "0px"
                          }
                        },
                        [
                          _vm._v(
                            " 官方公众号，主要推送前端技术类文章、框架资源、学习教程，以及 D2 系列项目更新信息 "
                          )
                        ]
                      )
                    ]
                  )
                ],
                1
              ),
              _c("d2-badge"),
              _c("d2-help")
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }