//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import D2Badge from './components/d2-badge';
import D2Help from './components/d2-help';
import D2PageCover from './components/d2-page-cover';
export default {
  components: {
    D2Badge: D2Badge,
    D2Help: D2Help,
    D2PageCover: D2PageCover
  }
};